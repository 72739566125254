<template>
  <div class="classContent">
    <div class="class_tabs flex">
      <div
        class="tabsList"
        :class="{ tabsList1: active == item.id }"
        @click="change(item.id)"
        v-for="(item, index) in tabs"
        :key="index"
      >
        {{ item.title }}
        <div v-if="active == index" class="border"></div>
      </div>
    </div>
    <!-- 课程列表 -->
    <div v-if="active == 0">
      <div class="classList" v-for="(item, index) in course1" :key="index">
        <div class="list_left">
          <img class="class_img" :src="item.alias_litpic" alt />
        </div>
        <div class="list_right">
          <div class="class_info">
            <div>
              <div class="title">{{ item.alias_name }}</div>
              <div class="next flex">
                <div>已学{{ item.finish_rate }}%</div>
                <div>上次学到：{{ item.subsection }}</div>
              </div>
            </div>
            <div class="tags">课时数量：{{ item.count }}</div>
          </div>
          <div class="button" @click="goDetail($event, item.culum_id, 1)">
            查看详情
          </div>
        </div>
      </div>
    </div>
    <!-- 直播课 -->
    <div v-if="active == 1">
      <div
        class="common zhiBo flex1"
        v-for="(item, index) in course2"
        :key="index"
      >
        <div class="boLeft">
          <div class="tag2">{{ item.action }}</div>
          <div class="title">{{ item.name }}</div>
          <div class="other">
            <span>直播时间：{{ item.start_time }}</span>
            <span>主讲人：{{ item.teacher }}</span>
          </div>
        </div>

        <div class="btn" @click="goDetail1(item.id)">查看详情</div>
      </div>
    </div>
    <!-- 系列课 -->
    <div v-if="active == 2">
      <div
        class="common series flex1"
        v-for="(item, index) in course3"
        :key="index"
      >
        <div class="seriesLeft">
          <div class="title">{{ item.alias_name }}</div>
          <div class="tabsList">
            <div v-for="(item1, index1) in item.label" :key="index1">
              {{ item1 }}
            </div>
          </div>
        </div>

        <div class="btn" @click="goDetail($event, item.id, 2)">查看详情</div>
      </div>
    </div>
  </div>
</template>
<script>
import { my_curriculum } from "../../assets/js/personal";
export default {
  data() {
    return {
      imgUrl: "http://v2.hnzye.cn",
      type: 1,
      active: 0,
      user_id: JSON.parse(window.localStorage.getItem("userInfo")).id,
      siteId: window.localStorage.getItem("siteId"),
      tabs: [
        {
          id: 0,
          title: "精品课",
        },
        // {
        //   id: 1,
        //   title: "直播课",
        // },
        {
          id: 2,
          title: "系列课",
        },
      ],
      course1: "",
      course2: "",
      course3: "",
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    change(i) {
      this.active = i;
      // console.log(i);
      this.type = i + 1;
      this.init();
    },
    init() {
      my_curriculum({
        type: this.type,
        user_id: this.user_id,
        siteId: this.siteId,
      }).then((res) => {
        if (this.type == 1) {
          this.course1 = res.data.list;
        } else if (this.type == 2) {
          this.course2 = res.data.list;
        } else {
          this.course3 = res.data.list;
        }
      });
    },
    goDetail(e, id, type) {
      this.$router.push({ path: "/detail", query: { id: id, active2: type } });
    },
    goDetail1(id) {
      this.$router.push({ path: "/detail2", query: { id: id } });
    },
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.classContent {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
}
.class_tabs {
  width: 930px;
  border-bottom: 1px solid #dddddd;
  .tabsList {
    cursor: pointer;
    width: 80px;
    font-size: 18px;
    color: #333333;
    height: 64px;
    line-height: 60px;
    padding: 0 20px;
    text-align: center;
    .border {
      border-bottom: 4px solid #0090f0;
    }
  }
  .tabsList1 {
    color: #0090f0;
  }
}
.classList {
  height: 190px;
  display: flex;
  align-items: center;
  margin: 0 25px;
  .list_left {
    display: flex;
    .class_img {
      width: 210px;
      height: 128px;
      margin-right: 25px;
    }
  }

  .list_right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 710px;
    height: 190px;
    border-bottom: 1px solid #f1f1f1;
    .class_info {
      height: 128px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        font-size: 18px;
        color: #333;
        font-weight: 500;
      }
      .next {
        font-size: 14px;
        color: #f13232;
        margin-top: 19px;
        div:last-child {
          margin-left: 30px;
          color: #333;
        }
      }
      .tags {
        color: "#999999";
      }
    }
    .button {
      width: 120px;
      height: 40px;
      color: #0090f0;
      border-radius: 20px;
      font-size: 14px;
      border: 1px solid #0090f0;
      text-align: center;
      line-height: 40px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .button:hover {
      background-color: #0090f0;
      color: #fefefe;
      border: 0;
    }
  }
}
.common {
  margin: 0 auto;
  margin-top: 34px;
  justify-content: space-between;
  width: 880px;
  height: 163px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  .title {
    padding-left: 25px;
    font-size: 20px;
    font-weight: 500;
  }
}
.btn {
  cursor: pointer;
  margin-right: 25px;
  width: 100px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  color: #0090f0;
  border: 1px solid #0090f0;
  border-radius: 18px;
  box-sizing: border-box;
  &:hover {
    color: #fff;
    background-color: #0090f0;
    border: 0;
  }
}
.zhiBo {
  .boLeft {
    height: 163px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .tag2 {
      width: 108px;
      height: 26px;
      line-height: 26px;
      background-color: rgba(0, 144, 240, 0.1);
      color: #0090f0;
      border-radius: 0px 0px 10px 0px;
      span {
        margin-right: 30px;
      }
    }
    .other {
      padding-left: 25px;
      margin-bottom: 30px;
      font-size: 12px;
      color: #999999;
      span {
        margin-right: 20px;
      }
    }
  }
}

.seriesLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 90px;
  .tabsList {
    margin-left: 25px;
    div {
      margin-right: 10px;
      display: inline-block;
      padding: 0 10px;
      height: 24px;
      line-height: 24px;
      background-color: rgba(0, 144, 240, 0.1);
      border-radius: 4px;
      font-size: 12px;
      color: #0090f0;
    }
  }
}
</style>